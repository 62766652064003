<template>
  <div class="nav-container">
    <!-- <div class="dropdown"> -->
    <!-- <button
        class="dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style="background: #fff; border: none"
      >
        <i class="bi bi-list" style="font-size: 32px"></i>
      </button> -->
    <i class="bi bi-list burguer" @click="this.setMenu"> </i>
    <div class="menu" v-if="this.menu">
      <div
        class="item-menu"
        v-for="(item, index) in this.ListMenu"
        :key="index"
        @click="this.setMenu"
      >
        <router-link :to="item.link" class="link">
          <h6>
            {{ item.titulo }}
          </h6>
        </router-link>
        <div class="linea-menu"></div>
      </div>
    </div>
    <!-- <ul class="dropdown-menu">
        <li>
          <router-link :to="'/munienlinea'" class="dropdown-item"
            >Inicio</router-link
          >
        </li>

        <li>
          <router-link :to="`/presentadas`" class="dropdown-item"
            >Presentadas</router-link
          >
        </li>
        <li>
          <router-link :to="`/notificaciones`" class="dropdown-item"
            >Notificaciones</router-link
          >
        </li>
        <li>
          <router-link :to="`/turneroweb`" class="dropdown-item"
            >Turnos</router-link
          >
        </li>
      </ul> -->
    <!-- </div> -->
    <router-link :to="`/munienlinea`">
      <img
        src="./../../assets/images/logo-muni.svg"
        alt="Luque"
        class="imagenlogo"
      />
    </router-link>

    <!--mi cuenta-->
    <div class="usuario">
      <div class="circuloAvatar">
        <div class="circuloInt">
          <img src="./../../assets/images/AgenteMunicipal.svg" alt="imagen" />
        </div>
      </div>
      <div class="usuario-details" v-if="this.$store.state.user">
        <div>
          <h4 style="color: var(--verdePrincipal)">
            Hola
            <strong> {{ $store.state.user?.firstname }}! </strong>
          </h4>
          <!-- datos del respresentante si lo hay -->
          <div>
            <p
              v-if="this.$store.state.representante"
              style="font-size: 13px; margin-top: -0.5rem"
            >
              Representado por:
              <strong class="nameRepresentative">
                {{ $store.state.representante?.firstname }}
                {{ $store.state.representante?.lastname }}
              </strong>
            </p>
          </div>
          <!-- <h5 style="margin-top: -1rem">CUIL: {{ $store.state.user.cuil }}</h5> -->
          <router-link v-show="permission" :to="`/micuenta`">
            <p style="font-size: 15px; font-weight: 600; color: var(--texto)">
              Tus datos
            </p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="muniEnlinea">
      <router-link to="/munienlinea">
        <img
          class="logo scale-up-center"
          src="./../../assets/images/MuniEnLinea.svg"
          alt=""
        />
      </router-link>
      <div class="botones">
        <div
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          @click="getNotifications"
          style="position: relative"
        >
          <img src="./../../assets/images/Campana.svg" alt="" />
          <p class="noti" v-if="notRead != 0" style="color: #fff">
            {{ notRead }}
          </p>
        </div>
        <div data-bs-target="#exampleModalToggle" data-bs-toggle="modal">
          <img src="./../../assets/images/Off.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- MODAL DE NOTIFICACIONES -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      data-bs-scroll="true"
      data-bs-backdrop="static"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
          "
        >
          <div class="botonNotificacionCanvas">
            <i class="bi bi-bell" style="width: 32px; height: 32px"> </i>
          </div>
          <h5 id="offcanvasRightLabel">Notificaciones</h5>
        </div>

        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body" v-if="this.notificaciones">
        <div
          v-for="(item, index) in this.notificaciones"
          :key="index"
          class="notificacion"
        >
          <div style="width: 75%">
            <h6 style="word-break: break-all">{{ item.subject }}</h6>
            <p>{{ item.message }}</p>
          </div>

          <h6 class="fecha">
            <!-- {{ new Date(item.created_at).getDate() }}/{{
              new Date(item.created_at).getMonth() + 1
            }}/{{ new Date(item.created_at).getFullYear() }} -->
            {{ new Date(item.created_at).toLocaleDateString() }}
          </h6>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL DE CIERRE DE SESION -->
  <div
    class="modal fade"
    id="exampleModalToggle"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h1>
            <i
              class="bi bi-exclamation-circle"
              style="color: #ffb900; margin-right: 8px; font-size: 25px"
            ></i
            >¿Estás seguro que deseas salir?
          </h1>
        </div>
        <div class="footer">
          <p data-bs-dismiss="modal" aria-label="Close" style="cursor: pointer">
            Rechazar
          </p>
          <button
            class="botonSubmit"
            data-bs-target="#exampleModalToggle2"
            data-bs-toggle="modal"
            @click="cerrarSesion"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- SEGUNDO MODAL DE DESPEDIDA DE CIERRE DE SESION -->
  <div
    class="modal fade"
    id="exampleModalToggle2"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modaldos">
        <div class="modal-body">
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="this.logOf"
          ></button> -->
          <h1 style="margin-bottom: 10px">¡Gracias por visitarnos!</h1>
          <p style="margin-bottom: 24px">
            Recordá que estamos aquí para ayudarte.
          </p>
          <p>¡Hasta pronto!</p>
        </div>
        <div class="footer" style="justify-content: end">
          <img
            src="./../../assets/images/logo-muni.svg"
            alt="Luque"
            class="imagenlogo"
          />
          <img
            class="logo scale-up-center"
            src="./../../assets/images/MuniEnLinea.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import setToken from "@/middlewares/setToken";
import setTokenRelations from "@/middlewares/setTokenRelations";
import dbService from "@/services/dbService";

import { BASE_URL } from "@/env";
export default {
  name: "NavTopVue",
  data() {
    return {
      menu: false,
      ruta: this.$router.currentRoute.value.fullPath,
      notificaciones: null,
      permission: true,
      user: "",
      representante: "",
      tokenUser: "",
      refreshTokenUser: "",
      cidiCookie: "",
      ListMenu: [
        { titulo: "INICIO", link: "/munienlinea" },
        { titulo: "TURNOS", link: "/turneroweb" },
        { titulo: "TUS GESTIONES", link: "/presentadas" },
        { titulo: "COMUNICACIONES", link: "notificaciones" },
        { titulo: "TUS DATOS", link: "/micuenta" },
      ],
      notRead: 0,
      // "https://res.cloudinary.com/ddko88otf/image/upload/v1692727232/240_F_65772719_A1UV5kLi5nCEWI0BNLLiFaBPEkUbv5Fv_t3fopl.jpg",
    };
  },

  created() {
    this.role = this.$store.state.user?.role;
    this.getNotifications();
    let Cookiess = document.cookie.split(";"); //tomo todas la cookkies
    // console.log(Cookiess, "soy las cookies");
    let asd = Cookiess?.filter((element) => {
      //las recorro para buscar la de cidi
      // if (element.includes("cidi")) {
      return element.includes("token");
      // }
    });
    let refresh = Cookiess?.filter((element) => {
      //las recorro para buscar la de cidi
      // if (element.includes("cidi")) {
      return element.includes("refreshToken");
      // }
    });
    let cidi = Cookiess?.filter((element) => {
      //las recorro para buscar la de cidi
      // if (element.includes("cidi")) {
      return element.includes("cidi");
      // }
    });
    // console.log(asd);

    let cidiCook = cidi[0]?.split("=") || null;

    if (cidiCook) {
      this.cidiCookie = cidiCook[1];

      // console.log(this.cidiCookie, "soy la cookie del cidiiiiiii");
      this.dispatchCidi();
    }

    // console.log(cidi);
    if (asd.length > 0) {
      let token = asd[0]?.split("=") || null;
      let refre = refresh[0]?.split("=") || null;
      this.tokenUser = token[1];
      this.refreshTokenUser = refre[1];
      if (this.tokenUser) {
        // this.logCidi(tokenUser);
        let payload = dbService.getToken(this.tokenUser);
        let role = payload.role;

        localStorage.setItem("token", this.tokenUser);
        localStorage.setItem("refreshToken", this.refreshTokenUser);
        localStorage.setItem("role", role);

        // this.getMyProfile();
      }
    }

    let idRepresentante = localStorage.getItem("idRepresentante") || null;
    if (idRepresentante) {
      this.getRepresentante(idRepresentante);
    }
  },

  methods: {
    setMenu() {
      this.menu = !this.menu;
    },
    cerrarSesion() {
      var modal = document.getElementById("exampleModalToggle2");
      setTimeout(() => {
        modal.classList.remove("show");
        modal.classList.add("hide");
        var backdrop = document.getElementsByClassName("modal-backdrop")[0];
        backdrop.parentNode.removeChild(backdrop);
        this.logOf();
      }, 3000);
    },
    dispatchLogin() {
      this.$store.dispatch("mockLoginAction", this.user);
    },
    dispatchLoginPermission() {
      this.$store.dispatch("mockPaseAction");
    },
    dispatchRepresentante() {
      this.$store.dispatch("mockRepresentanteAction", this.representante);
    },
    getMyProfile() {
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": this.tokenUser,
        },
      });
      apiClient
        .get("/oficina/user/profile")
        .then((response) => {
          // console.log(response.data, "datos de usuariodb, navtop");
          this.user = response.data.UserProfile.user;
          // this.user.cidiCookie = this.cidiCookie;
          this.dispatchLogin();
          this.dispatchLoginPermission();

          // window.localStorage.setItem(
          //   "role",
          //   response.data.UserProfile.user.role
          // );
          // window.localStorage.setItem(
          //   "name",
          //   response.data.UserProfile.user.firstname
          // );
          // window.localStorage.setItem(
          //   "lastname",
          //   response.data.UserProfile.user.lastname
          // );
          // window.localStorage.setItem(
          //   "cuil",
          //   response.data.UserProfile.user.cuil
          // );
          // window.localStorage.setItem(
          //   "adress",
          //   response.data.UserProfile.user.adress
          // );
          // window.localStorage.setItem(
          //   "email",
          //   response.data.UserProfile.user.email
          // );
          // window.localStorage.setItem("id", response.data.UserProfile.user.id);
          // window.localStorage.setItem(
          //   "fecha-creacion",
          //   response.data.UserProfile.user.created_at
          // );
          // window.localStorage.setItem(
          //   "nivel",
          //   response.data.UserProfile.user.level.level
          // );

          // this.loading = false;
          // this.$router.push("munienlinea");
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 500) {
            if (error.response.data.message === "Token de usuario expirado") {
              setToken();
              this.getMyProfile();
            }
            if (
              error.response.data.message === "Token de representante expirado"
            ) {
              setTokenRelations();
              this.getMyProfile();
            }
          }
        });
    },
    //METODO PARA BUSCAR LAS NOTIFICACIONES DEL USUARIO PARA MOSTRAR EN MODAL LATERAL DE NOTIFICACIONES.
    getNotifications() {
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient
        .get("/communications/my-communications?page=1")
        .then((response) => {
          // console.log(response.data, "soy las notificaciones");
          this.notificaciones = response.data.Communications;
          let cantidad = 0;
          for (let index = 0; index < this.notificaciones.length; index++) {
            if (this.notificaciones[index].read === false) {
              cantidad = cantidad + 1;
            }
          }
          if (cantidad > 9) {
            cantidad = `9+`;
          } else {
            this.notRead = cantidad;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.lengtNotifications = 0;
          if (error.response.status === 500) {
            if (error.response.data.message === "Token de usuario expirado") {
              setToken();
              this.getNotifications();
            }
            if (
              error.response.data.message === "Token de representante expirado"
            ) {
              setTokenRelations();
              this.getNotifications();
            }
          }
        });
    },
    //METODO PARA BUSCAR LOS DATOS DEL REPRESENTANTE
    getRepresentante(id) {
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
          "access-user-header":
            "^Yh19S&^8$yl01&Fagyg8eLxrI8uxypiCpdUdRscjF!xKSSqq",
        },
      });
      apiClient
        .get("/oficina/users/" + id)
        .then((response) => {
          // console.log(response.data, "datos representante");

          this.representante = response.data.User;
          // localStorage.setItem(
          //   "representanteFirstname",
          //   response.data.User.firstname
          // );
          // localStorage.setItem(
          //   "representanteLastname",
          //   response.data.User.lastname
          // );

          this.dispatchRepresentante();
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.status === 500) {
            if (error.response.data.message === "Token de usuario expirado") {
              setToken();
              this.getRepresentante(id);
            }
            if (
              error.response.data.message === "Token de representante expirado"
            ) {
              setTokenRelations();
              this.getRepresentante(id);
            }
          }
        });
    },
    //METODO PARA LIMPIAR LE SESION, COOKIE Y LOCALSTORAGE
    logOf() {
      localStorage.clear();
      // const url = window.location.host; // Obtiene la URL base sin parámetros
      // window.history.replaceState({}, url);
      this.dispatchOutLogin();
      // location.replace(url);
      this.$router.push("/login");
      window.location.reload();
      // googleLogout();
      document.cookie = "cidi=; max-age=0";
      document.cookie = "token=; max-age=0";
      document.cookie = "refreshToken=; max-age=0";

      window.dispatchEvent(
        new CustomEvent("token-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("token"),
          },
        })
      );
    },
    dispatchOutLogin() {
      this.$store.dispatch("mockOutAction");
    },
    dispatchCidi() {
      this.$store.dispatch("mockCidiAction", this.cidiCookie);
    },
    logCidi(cidi) {
      this.dispatchCidi();
      // console.log(this.cidiCookie, "cidicookie");
      // console.log("entre en el logcidi");
      //SE ENVIA LA QUERY PARA OBTENER TODA LA INFO DEL USUARIO
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
      });
      apiClient
        .post("/auth/cidi/login/" + cidi)

        .then((response) => {
          // console.log(response.data, "respuesta api cidi");
          // console.log("no hay respuesta de cidi");
          let token = response.data["Tokens"] || null; //token por calve fizcal o sin representados
          // let redireccionamiento = response.data["redirectURL"] || null; //redireccionamiento con representados
          let tokenRepresetations =
            response.data["TokenRepresentations"] || null; //token con representados

          if (token) {
            localStorage.setItem("token", token.authToken);
            localStorage.setItem("refreshToken", token.refreshToken);

            // this.getMyProfile();
            // this.$router.push("munienlinea");
          }

          //si tiene representados
          // if (redireccionamiento) {
          //   window.location.href = response.data.redirectURL;
          // }
          if (tokenRepresetations) {
            localStorage.setItem("token", tokenRepresetations.authToken);
            localStorage.setItem(
              "refreshToken",
              tokenRepresetations.refreshToken
            );

            //se buscan los datos del usuario al que representa
            let payload = dbService.getToken(tokenRepresetations.authToken);
            let idRepresentante = payload.representative;
            localStorage.setItem("idRepresentante", idRepresentante);
            this.getMyProfile();
            //se busca los datos del representante
            this.getRepresentante(idRepresentante);
            // this.$router.push("munienlinea");
          }
        })
        // .catch((error) => {
          // console.log(error);
        // })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
.noti {
  position: absolute;
  top: -8px;
  right: 0;
  font-size: 15px;
  font-weight: 700;
  background: red;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  color: #fff;
}
/* CSS NUEVO */
.dropdown {
  display: none;
}
.nav-container {
  position: fixed;
  z-index: 5;
  height: 102px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding: 13px 28px;
}
.imagenlogo {
  width: 144px;
  height: 44px;
  position: relative;
  /* margin-left: 2.6vw; */
}
.usuario {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: left;
  margin-left: 75px;
  /* margin-top: -1%; */
}
.circuloAvatar {
  width: 76px;
  height: 75px;
  background-image: var(--rojoDegrade);
  border-radius: 50%;
  padding: 3px;
  /* border: 3px solid red; */
}
.circuloInt {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: white;
}
.circuloAvatar img {
  width: 100%;
  height: 100%;
}

.usuario-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  text-align: left;
  font-size: 20px;
  color: var(--verdePrincipal);
  width: 360px;
}
.botones {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  position: relative;
}
.botones img {
  cursor: pointer;
}
.botonNotificacion {
  position: relative;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background-image: var(--rojoDegrade);
  /* margin-right: 23px; */
  font-size: 28px;
  color: whitesmoke;
  text-align: center;
  padding-right: 2px;
  /* padding-top: 3px; */
  transform: rotate(40deg);
  cursor: pointer;
}
.botonNotificacionCanvas {
  background-image: var(--rojoDegrade);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: whitesmoke;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  transform: rotate(40deg);
}
/* .botonNotificacionCanvas {
  background-image: var(--rojoDegrade);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: whitesmoke;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  transform: rotate(40deg);
}
.botonOut {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background-image: var(--rojoDegrade);

  font-size: 28px;
  color: whitesmoke;
  text-align: center;
  cursor: pointer;
} */
.logo {
  width: 94px;
  height: 48px;
}
.muniEnlinea {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 51px;
  justify-content: space-between;
  position: absolute;
  right: 30px;
}
a {
  text-decoration: none;
  color: var(--verdePrincipal);
}
.nameRepresentative {
  cursor: pointer;
  color: var(--verdePrincipal) 4;
}
h5 {
  color: #ff2745;
}
.offcanvas-end {
  /* width: 100%; */
  border-radius: 16px 0px 0px 16px;
  background: #fff;
  /* width: 706px; */
  /* padding: 32px 24px; */
}
.notificacion {
  border-bottom: 1px solid var(--bordecard);
  margin-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 32px;
  /* word-break: break-all; */
  width: 100%;
  justify-content: space-between;
  /* text-align: left; */
}
.offcanvas-header h5 {
  color: var(--verdePrincipal);
  font-size: 17.5px;
  font-style: normal;
  font-weight: 600;
  /* margin-left: -100px; */
}
.offcanvas-body h6 {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.offcanvas-body p {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.offcanvas-body h6:hover {
  color: var(--verdePrincipal);
}
.fecha {
  position: absolute;
  right: 0;
  top: 0;
}
.modal-content {
  padding: 32px 26px;
  height: 244px;
  border-radius: 32px;
  margin: auto;
}
.modaldos {
  text-align: left;
  height: auto;
}
.btn-close {
  position: absolute;
  right: 24px;
  top: 32px;
}
.modal-body h1 {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.modal-body p {
  font-weight: 400;
  color: var(--texto);
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 32px;
  padding: 15px;
}
.botonSubmit {
  width: 150px;
  height: 53px;
  background: linear-gradient(270deg, #00c3a8 0.07%, #72c057 251.05%);
  border-radius: 12px;
  color: white;
  font-size: 17.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-style: none;
}
.burguer {
  display: none;
}
/* ------------------------------------------------------------ */

@media (max-width: 900px) {
  .burguer {
    display: block;
    margin-left: 0px;
    font-size: 30px;
    margin-right: 15px;
    color: #9b9a9a;
  }
  .menu {
    display: block;
    width: 100%;
    background: #fff;
    position: absolute;
    top: 100px;
    left: 0px;
    /* padding: 24px ; */
    border-top: 1px solid var(--grisMedio);
    border-radius: 0px 0px 16px 16px;
  }
  .item-menu {
    padding: 10px 0px;
    position: relative;
  }
  .item-menu h6 {
    font-size: 11px;
    font-weight: 600;
    padding-left: 16px;
  }
  .linea-menu {
    height: 1px;
    width: 100%;
    background: var(--bordeDegradeCompleto);
    margin-top: 10px;
    /* position: absolute; */
    /* bottom: 0; */
  }
  .offcanvas-end {
    width: 200px;
    border-radius: 16px 0px 0px 16px;
    background: #fff;
    /* width: 706px; */
    /* padding: 32px 24px; */
  }
  .offcanvas.offcanvas-end {
    width: 300px;
  }
  /* button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 10px;
  } */

  /* .muniEnlinea {
    width: 40vw;
  } */
  .usuario {
    display: none;
  }
}
@media (max-width: 770px) {
  .dropdown {
    display: block;
  }
  .burguer {
    display: block;
    margin-left: 0px;
    font-size: 30px;
    margin-right: 15px;
    color: #9b9a9a;
  }
  .nav-container {
    position: fixed;
    z-index: 20;
    height: 75px;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 0px 16px;
  }
  .imagenlogo {
    width: 117px;
    height: 36px;
    position: relative;
    /* margin-bottom: 5px; */
  }
  .usuario {
    display: none;
  }
  .circuloAvatar {
    width: 32px;
    height: 32px;
    background-image: var(--rojoDegrade);
    border-radius: 50%;
    padding: 3px;
    /* border: 3px solid red; */
  }
  .usuario-details {
    display: none;
  }
  .muniEnlinea {
    /* position: relative; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 51px; */
    justify-content: right;
    /* right: 30px; */
  }
  .logo {
    display: none;
  }
  .dropdown-menu {
    width: 100%;
  }
  .botones img {
    cursor: pointer;
    height: 41px;
    width: 41px;
  }
  .menu {
    display: block;
    width: 100%;
    background: #fff;
    position: absolute;
    top: 75px;
    left: 0px;
    /* padding: 24px ; */
    border-top: 1px solid var(--grisMedio);
    border-radius: 0px 0px 16px 16px;
  }
  .item-menu {
    padding: 10px 0px;
    position: relative;
  }
  .item-menu h6 {
    font-size: 11px;
    font-weight: 600;
    padding-left: 16px;
  }
  .linea-menu {
    height: 1px;
    width: 100%;
    background: var(--bordeDegradeCompleto);
    margin-top: 10px;
    /* position: absolute; */
    /* bottom: 0; */
  }
  .offcanvas-end {
    width: 200px;
    border-radius: 16px 0px 0px 16px;
    background: #fff;
    /* width: 706px; */
    /* padding: 32px 24px; */
  }
  .offcanvas.offcanvas-end {
    width: 300px;
  }
  .btn-close {
    position: absolute;
    right: 24px;
    top: 12px;
  }
}
</style>
