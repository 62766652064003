<template>
  <div class="panelGeneral" id="sidebarMenu">
    <div class="NavMuni">
      <div class="bn3">
        <router-link :to="'/muni-agentes'">
          <img src="@/assets/images/Trámites.svg" alt="" class="iconos" />
        </router-link>
        <h5 class="textPanel">Trámites</h5>
      </div>
      <div class="bn3">
        <router-link :to="'/turnero'">
          <img src="@/assets/images/Turnos.svg" alt="" class="iconos" />
        </router-link>
        <h5 class="textPanel">Turnos</h5>
      </div>
      <div class="bn3">
        <router-link :to="'/tareas'">
          <img src="@/assets/images/Tareas.svg" alt="" class="iconos" />
        </router-link>
        <h5 class="textPanel">Tareas</h5>
      </div>
      <div class="bn3">
        <router-link :to="'/tabla-vecinos'">
          <img src="@/assets/images/Vecinos.svg" alt="" class="iconos" />
        </router-link>
        <h5 class="textPanel">Vecinos</h5>
      </div>
      <div class="bn3" v-if="hasPrensaAccess">
        <router-link :to="'/noticias'">
          <img src="@/assets/images/Noticias.svg" alt="" class="iconos" />
        </router-link>
        <h5 class="textPanel">Noticias</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavPanelComponent",
  computed: {
    user() {
      return this.$store.state.user;
    },
    hasPrensaAccess() {
      return this.checkPrensaArea();
    },
  },
  methods: {
    checkPrensaArea() {
      let isPrensa = false;
      if (this.user && this.user.muniAreas) {
        for (let i = 0; i < this.user.muniAreas.length; i++) {
          if (this.user.muniAreas[i].area?.area_name === "PRENSA") {
            isPrensa = true;
            break;
          }
        }
      }

      return isPrensa;
    },
  },
  mounted() {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      this.user = JSON.parse(storedUser);
    }
  },
};
</script>

<style scoped>
.panelGeneral {
  display: flex;
  /*background-color: #f4f4f4;*/
  position: fixed;
  height: 520px;
  background: var(--verde-10, rgba(0, 195, 168, 0.1));
  border-radius: 0px 0px 20.102px 0px;
  width: 129.22px;
  padding: 15px;
  margin-top: 78px;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NavMuni {
  /*position: inline-flex;*/
  /*margin-top: 108px;*/
  gap: 13px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.iconos {
  width: 40px;
  height: 40px;
  margin-bottom: 9.57px;
}

.textPanel {
  color: var(--texto);
  text-align: center;
  /* font-family: Montserrat; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.bn3 {
  text-align: center;
}
</style>
